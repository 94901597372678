<template>
  <div class="fale-conosco">
    <Voltar />
    <h1 class="titulo">Fale conosco</h1>
    <span class="texto-destaque">PRECISA DE AJUDA?</span>
    <p>Veja o passo-a-passo para ser um assinante RedeTV! Plus</p>
    <iframe class="fale-conosco__video" src="https://player.vimeo.com/video/593296509" style="height: 377px" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>

    <p class="texto-destaque">Ainda tem dúvidas?</p>
    <p>Escolha um assunto e nos envie sua mensagem</p>

    <form class="form" @submit.prevent="handleSubmit">
      <div>
        <label for="nome">Nome</label>
        <input type="text" id="nome" maxlength="30" v-model="state.nome" required />
      </div>

      <div>
        <label for="email">Email</label>
        <input type="email" id="email" maxlength="100" v-model="state.email" required />
      </div>

      <div class="form__select">
        <label for="assunto">Assunto</label>
        <select name="assunto" v-model="state.assunto" required>
          <option selected disabled hidden>Escolha um assunto</option>
          <option value="Elogio">Elogio</option>
          <option value="Informação">Informação</option>
          <option value="Reclamação">Reclamação</option>
          <option value="Solicitação">Solicitação</option>
          <option value="Sugestão">Sugestão</option>
        </select>
      </div>

      <div class="margen-top">
        <label for="texto">Mensagem</label>
        <textarea name="mensagem" cols="30" rows="10" v-model="state.mensagem" required></textarea>
      </div>

      <div class="bt">
        <button type="submit" :disabled="state.isLoading">Enviar</button>
      </div>
    </form>
    <footer class="fale-conosco__rodape">
      <p>Se preferir entre em contato por:</p>
      <p><span class="texto-destaque">Telefone: </span> (XX) 0000-0000</p>
      <p>
        <span class="texto-destaque"> E-mail: </span>
        faleconosco@redeamericapremios.com.br
      </p>
    </footer>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useToast } from 'vue-toastification'
import services from '../../services'
import Voltar from '../../components/Voltar'

export default {
  setup () {
    const toast = useToast()

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      nome: '',
      email: '',
      assunto: '',
      mensagem: ''
    })

    async function handleSubmit () {
      toast.clear()
      try {
        state.isLoading = true
        const { data, errors } = await services.faleconosco.index({
          nome: state.nome,
          email: state.email,
          assunto: state.assunto,
          mensagem: state.mensagem
        })

        if (!errors && !data.error_message) {
          toast.success('Obrigado! E-mail Enviado com Sucesso!')
        } else {
          toast.error('Não foi possível enviar seu e-mail! Entre em contato com o Administrador! - 1')
        }

        state.isLoading = false
      } catch (error) {
        toast.error('Não foi possível enviar seu e-mail! Entre em contato com o Administrador! - 2')
        console.log(error)
        state.isLoading = false
        state.hasErrors = !!error
      }
    }

    return {
      state,
      handleSubmit
    }
  },
  components: { Voltar }
}
</script>
